<template>
  <v-container
    fluid
    tag="section"
    class="mt-1"
  >
    <v-card class="mb-16">
      <v-card-title class="card-title">
        <h4>{{ $t('regionalOffices') }}</h4>
        <v-spacer />
        <v-btn
          v-if="addPermission"
          color="primary"
          rounded
          small
          dark
          @click="addClick"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <alert
        v-if="alertEnabled"
        :alert-msg="alertMsg"
        :alert-type="alertType"
      />
      <v-data-table
        :loading="loading"
        :headers="mainHeaders"
        :items="filteredOffices"
        :items-per-page="filteredOffices.length"
        :footer-props="footerProps"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        dense
        sort-by="Id"
        item-key="Id"
        fluid
        fixed-header
        style="cursor:pointer"
        class="elevation-1"
        @click:row="expand"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="3"
                offset="8"
              >
                <v-select
                  v-if="!loading"
                  v-model="selectedStatus"
                  :items="regionStatuses"
                  :loading="loading"
                  item-text="ShortDescription"
                  item-value="LowValue"
                  dense
                  placeholder="All"
                  multiple
                  class="mt-6"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
            <manage-regional-office
              v-if="dialog"
              :dialog="dialog"
              @manage-region-dialog-closed="dialogClosed"
              @successfully-added-regional-office="addedRegionalOffice"
            />
          </v-toolbar>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
          >
            <v-card>
              <v-card-title class="text-h5">
                {{ $t('areYouSureDelete') }}
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDelete"
                >
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteRegionalOfficeConfirm"
                >
                  {{ $t('ok') }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <details-card
              :details="selectedRegion"
              :type="'regions'"
              @refresh-regional-offices="getRegionalOffices"
            />
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                @click="deleteItem(item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('deleteoffice') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { checkPermission } from '@/router/permissions'
  import ManageRegionalOffice from '../Dialogs/ManageRegionalOffice.vue'
  import DetailsCard from '../Cards/DetailsCard.vue'
  import Alert from '../component/Alert.vue'
  export default {
    name: 'DashboardDashboard',
    components: {
      ManageRegionalOffice,
      DetailsCard,
      Alert,
    },
    data: () => ({
      alertEnabled: false,
      alertMsg: '',
      alertType: '',
      selectedRegionalOffice: {},
      search: '',
      loading: true,
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      regionalOfficeToBeDeleted: -1,
      filterStoreStatus: 'All',
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      regionaloffices: [],
      verifiers: [],
      expanded: [],
      selectedStatus: [],
    }),
    computed: {
      ...mapState({
        officesFromRepo: (state) => state.regionaloffice.regionaloffices,
        userData: (state) => state.authentication.userData,
        selectedRegion: (state) => state.regionaloffice.selectedRegionalOffice,
        regionStatuses: (entytiStatuses) => entytiStatuses.verifier.regionStatuses,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      mainHeaders () {
        const result = [
          { text: this.$t('Id'), value: 'Id', align: 'start' },
          { text: this.$t('regionalofficename'), value: 'RegionalOfficeName' },
          { text: this.$t('contact-email'), value: 'ContactEmail' },
          { text: this.$t('contact-phone-number'), value: 'ContactPhone' },
          { text: this.$t('contact-person'), value: 'ContactPerson' },
          { text: this.$t('status'), value: 'StatusForDisplay' },
        ]
        if (this.deletePermission) result.push({ text: this.$t('actions'), value: 'actions', sortable: false })
        return result
      },
      filteredOffices () {
        let offices = []
        this.regionaloffices.forEach(() => {
          if (this.selectedStatus.length === 0) {
            offices = this.regionaloffices
          } else {
            offices = this.regionaloffices.filter(office => this.selectedStatus.includes(office.Status))
          }
        })
        return offices
      },
      addPermission () {
        return checkPermission(this.userData.roleData.roleName, 'regions', 'add')
      },
      deletePermission () {
        return checkPermission(this.userData.roleData.roleName, 'regions', 'delete')
      },
    },
    async mounted () {
      await this.getRegionalOffices()
    },
    methods: {
      editItem (item) {
        Object.assign(this.selectedRegionalOffice, item)
        this.dialogEdit = true
      },
      deleteItem (item) {
        this.regionalOfficeToBeDeleted = item.Id
        this.dialogDelete = true
      },
      dialogClosed () {
        this.dialogEdit = false
        this.dialog = false
        this.getRegionalOffices()
      },
      closeDelete () {
        this.regionalOfficeToBeDeleted = -1
        this.dialogDelete = false
      },
      deleteRegionalOfficeConfirm () {
        this.$store.dispatch('regionaloffice/deleteRegionalOffice', this.regionalOfficeToBeDeleted)
          .then(res => {
            if (res.resFlag) {
              this.dialogDelete = false
              this.getRegionalOffices()
            }
          })
      },
      addClick () {
        this.$nextTick(() => {
          this.dialog = true
        })
      },
      async expand (item) {
        await this.$store.dispatch('regionaloffice/getRegionalOfficesById', item.Id)
        if (item === this.expanded[0]) this.expanded = []
        else this.expanded = [item]
      },
      async getRegionalOffices () {
        this.loading = true
        await this.$store.dispatch('regionaloffice/getRegionalOffices')
          .then(() => {
            this.regionaloffices = this.officesFromRepo
            this.regionaloffices.forEach(r => {
              r.StatusForDisplay = this.getVerifierShortDescription(this.regionStatuses, r.Status)
            })
            this.loading = false
          })
      },
      addedRegionalOffice (msg) {
        this.alertEnabled = true
        this.alertMsg = msg
        this.alertType = 'success'
      },
    },
  }
</script>

<style scoped>
  .container--fluid {
    height: 100vh !important;
  }
</style>
